<template>
  <section ref="section" v-if="item.ctaText" class="containerized cta -visibility-auto">
    <Observer name="cta-uper" @enter="enter" class="container">
      <h2 class="h3 title -upper" v-if="item.ctaTitle" v-text="item.ctaTitle"></h2>
      <router-link v-if="item.ctaText && !isAbsoluteURL" :to="localizedUrl + item.ctaLink" v-text="item.ctaText" :class="item.ctaClasses"></router-link>
      <a v-else-if="item.ctaText && isAbsoluteURL" :href="externalURL" v-text="item.ctaText" :class="item.ctaClasses" target="_blank">{{ item.ctaText }}</a>
    </Observer>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'pagePosition'],
  mixins: [ReadyHook],
  name: 'Cta',
  data() {
    return {
      appeared: false
    }
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    isAbsoluteURL() {
      return (this.item.ctaLink.indexOf('http://') === 0 || this.item.ctaLink.indexOf('https://') === 0 || this.item.ctaLink.indexOf('www.') === 0)
    },
    externalURL() {
      return 'https://' + this.item.ctaLink.replace(/^https?:\/\//, '')
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

section {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 14rem;
  text-align: center;

  @include bp(max md) {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }

  a {
    font-size: 1.2em;
    margin: 0;
  }
}
</style>
