import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isEcommerce as isEcommerceAction } from '../../../themes/zenith/utilities'

const state = {
  menu: {
    ecommerce: false,
    open: false,
    section: null,
    mobile: false
  },
  search: {
    open: false
  },
  lang: {
    open: false
  },
  logo: {
    url: null,
    homepageWhiteLogoMobile: true,
    invertLogoOnTransparentHeader: true
  },
  logo_160: {
    baseUrl: null,
    onTransparentUrl: null,
    isVisible: false
  }
}
const mutations = {
  setMenu (state, { open = false, section = null }) {
    state.menu.open = open === true
    state.menu.section = section
  },
  setMobile (state, mobile = false) {
    state.menu.mobile = mobile === true
  },
  setSearch (state, open = false) {
    state.search.open = open === true
  },
  setEcommerceFlag (state, flag) {
    state.ecommerce = flag
  },
  setLang (state, {open}) {
    state.lang.open = open === true
  },
  setLogo (state, logo) {
    state.logo = logo
  },
  setLogo160(state, logo) {
    state.logo_160 = logo
  }
}
const actions = {
  isEcommerce ({ commit }) {
    const isDefaultEcom = currentStoreView().is_e_com
    return isEcommerceAction().then((result) => {
      commit('setEcommerceFlag', result)
      return result
    }).catch(() => {
      if (isDefaultEcom) {
        commit('setEcommerceFlag', true)
        return true
      }
      commit('setEcommerceFlag', false)
      return false
    })
  },
  toggleMenu (context) {
    if (context.state.menu.open) context.dispatch('closeMenu', context)
    else context.dispatch('rootMenu', context)
    context.dispatch('setBodyOverflow', context)
  },
  rootMenu (context) {
    context.commit('setMenu', {
      open: true,
      section: null
    })
  },
  closeMenu (context) {
    context.commit('setMenu', {
      open: false,
      section: null
    })
  },
  closeSearch (context) {
    context.commit('setSearch', {
      open: false
    })
  },
  setBodyOverflow (context) {
    if (!isServer) document.body.style.overflowY = context.state.menu.mobile && context.state.menu.open ? 'hidden' : 'visible'
  },
  resetMenu (context) {
    context.dispatch('closeMenu', context)
    context.dispatch('closeSearch', context)
    context.dispatch('setBodyOverflow', context)
  }
}
export const store = {
  namespaced: true,
  state,
  actions,
  mutations
}
